import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import axios from 'axios'
import { StaticQuery, graphql } from 'gatsby'
import { WEB_LEGACY_LINK, MARKETING_API } from '../../../config'
import moment from 'moment-timezone'
import { setCookie, readCookie } from '../../utils'
// schema.org in JSONLD format
// https://developers.google.com/search/docs/guides/intro-structured-data
// You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

const schemaOrgWebPage = {
  '@context': 'http://schema.org/',
  '@type': 'Organization',
  address: {
    '@type': 'PostalAddress',
    addressCountry: 'TH',
    addressLocality: 'Bangkok',
    postalCode: '10110',
    streetAddress:
      '193-195 Ratchadaphisek Rd, Khwaeng Khlong Toei, Khet Khlong Toei, Krung Thep Maha Nakhon 10110',
    email: 'support@drivehub.co',
    telephone: '+66 2 038 5222',
    alternateName: 'Drivehub',
    description:
      'จะต้องเสียเวลาในการค้นหารถเช่าอยู่ทำไม Drivehub ผู้ให้บริการค้นหาบริษัทเช่ารถรายวัน ราคาถูก มีรถหลากแบบทั้ง รถตู้ รถ SUV รถ Eco ไว้ในที่เดียว',
    name: 'Drivehub',
  },
  aggregateRating: {
    '@type': 'AggregateRating',
    reviewCount: '150',
    bestRating: '5',
    ratingValue: '5',
    worstRating: '1',
  },
  email: 'support@drivehub.co',
  foundingDate: '27 September 2018',
  legalName: 'Drivehub',
  telephone: '+66 2 038 5222',
  alternateName: 'Drivehub',
  description:
    'จะต้องเสียเวลาในการค้นหารถเช่าอยู่ทำไม Drivehub ผู้ให้บริการค้นหาบริษัทเช่ารถรายวัน ราคาถูก มีรถหลากแบบทั้ง รถตู้ รถ SUV รถ Eco ไว้ในที่เดียว',
  mainEntityOfPage: 'www.drivehub.co',
  name: 'Drivehub',
}

const SEO = props => (
  <StaticQuery
    query={graphql`
      query DefaultSEOQuery {
        defaultSite: allMarkdownRemark(
          filter: { fields: { collection: { eq: "site_description" } } }
          limit: 1000
        ) {
          edges {
            node {
              frontmatter {
                title
              }
              id
            }
          }
        }
      }
    `}
    render={data => <SeoSection {...props} data={data} />}
  />
)

class SeoSection extends PureComponent {
  componentDidMount() {
    setTimeout(() => {
      const duid = readCookie('__duid')
      duid !== null && this.userInit(duid)
    }, 500)
    window.addEventListener('beforeunload', e => {
      const datetimeEncoded = window.btoa(moment().format('YYYY-MM-DD HH:mm'))
      setCookie('_lv', datetimeEncoded, 365, '/')
    })
  }

  userInit = duid => {
    const init = readCookie('_init')
    const isUserHaveInit = init === null ? false : init

    if (isUserHaveInit === false) {
      axios
        .post(`${MARKETING_API}/voucher/init`, {
          duid: duid,
        })
        .then(() => setCookie('_init', true, 365, '/'))
    }
  }

  render() {
    const {
      lang,
      title,
      canonical,
      prefix,
      path,
      description,
      keywords,
    } = this.props

    const defaultSite = this.props.data.defaultSite.edges[0].node.frontmatter
    const seoTitle = title ? title : defaultSite.title

    return (
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={seoTitle}
        meta={[
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:type`,
            content: `article`,
          },
          {
            property: `og:url`,
            content: `${WEB_LEGACY_LINK}/${prefix}/${path ? path : ''}`,
          },
          {
            property: `og:image`,
            content: `https://d1p3p3st0uh13e.cloudfront.net/favicon/drivehub.jpg`,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:sitename`,
            content: `Drivehub`,
          },
          {
            name: `twitter:card`,
            content: `รวมบริษัทเช่ารถมืออาชีพชั้นนำทั่วประเทศไว้ในที่เดียว ค้นหารถเช่า เช็ครถว่าง เปรียบเทียบราคารถเช่า จองรถเช่าราคาถูก แบบเรียลไทม์!`,
          },
          {
            name: `twitter:creator`,
            content: `@drivehubTH`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:site`,
            content: `@drivehubTH`,
          },
          {
            name: `twitter:description`,
            content: description,
          },
          {
            name: `twitter:image`,
            content: `https://d1p3p3st0uh13e.cloudfront.net/favicon/drivehub_tw.jpg`,
          },
          {
            property: `fb:admins`,
            content: '320705601614112',
          },
          {
            name: `keywords`,
            content: keywords,
          },
          {
            name: `description`,
            content: description,
          },
        ]}
      >
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
        {/* <!-- Twitter universal website tag code --> */}
        {process.env.GATSBY_STAGE === 'production' ? (
          <script>
            {`
!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
twq('init','o1sto');
twq('track','PageView');
            `}
          </script>
        ) : (
          ''
        )}
        {/* <!-- End Twitter universal website tag code --> */}
        <link
          rel="canonical"
          href={`${canonical ? canonical : WEB_LEGACY_LINK}`}
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-57x57.png"
        />
        >
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://d1p3p3st0uh13e.cloudfront.net/favicon/favicon-16x16.png"
        />
        <script
          type="text/javascript"
          src="/scripts/niffler.min.js?v=0.0.4"
        ></script>
      </Helmet>
    )
  }
}

SeoSection.defaultProps = {
  lang: `th`,
  path: '',
}

SeoSection.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  keywords: PropTypes.string,
  title: PropTypes.string.isRequired,
  canonical: PropTypes.string,
  prefix: PropTypes.string,
  path: PropTypes.string,
}

export default SEO
