import React, { Component } from 'react'
import { Link } from 'gatsby'
import { WEB_LEGACY_MANAGE_LINK, WEB_LEGACY_LINK } from '../../../config'
import './style.scss'

class Footer extends Component {
  render() {
    const { prefix } = this.props
    const { t } = this.props
    return (
      <div className="container footer-container">
        <footer>
          <div className="flex" style={{ flexGrow: 1 }}>
            <div className="drivehub-address">
              <img
                className="logo"
                alt="Drivehub"
                height="26"
                src="/assets/logo-white.svg"
              />
              <p>
                Drivehub Co.,Ltd 193-195 Lake Rajada Office Complex,
                Ratchadapisek road, Khlong Toei, Bangkok
              </p>
              <a
                rel="noreferrer"
                href="tel:+6620385222"
                className="btn-outline"
              >
                <i className="flaticon-phone-icon" /> 02-038-5222
              </a>
              <div id="Certificate-banners">
                <a
                  href="https://www.trustmarkthai.com/callbackData/popup.php?data=e1-26-5-7561fa57af7580f8eaf21cba866846d785a25f4705e"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    alt="DBD Registered"
                    height="54"
                    id="dbd-logo"
                    src="/assets/bns_registered.jpg"
                  />
                </a>
              </div>
              <small>© Drivehub 2020</small>
            </div>
          </div>
          <div className="flex footer-list" style={{ flexGrow: 4 }}>
            <div className="flex-item">
              <h5>{t('footer.follow')}</h5>
              <ul className="list-inline flex-icons">
                <li>
                  <a
                    href="https://fb.com/drivehub.th"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="/assets/facebook-icon.png"
                      width={20.5}
                      alt="fb:drivehub.th"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/drivehubth"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="/assets/twitter-icon.png"
                      width={20.5}
                      alt="twitter:drivehubth"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href=" https://instagram.com/drivehub.th"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                  >
                    <img
                      src="/assets/instagram-icon.png"
                      width={20.5}
                      alt="instagram:drivehub.th"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item">
              <h5>{t('footer.about_us')}</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={`/${prefix}/services`}>
                    {t('footer.our_service')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${prefix}/help-center`}>
                    {t('footer.help_center')}
                  </Link>
                </li>
                <li>
                  <a rel="dofollow noreferrer" href="http://blog.drivehub.co">
                    {t('footer.bloq')}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="https://recruit.zoho.com/recruit/Portal.na?iframe=false&amp;digest=JYnau1WzvikWKdDGIw48QHGAUnkE7bVo.a85bxdBEmQ-"
                  >
                    {t('footer.job')}
                  </a>
                </li>
                <li>
                  <Link to={`/${prefix}/contact/`}>{t('footer.contact')}</Link>
                </li>
              </ul>
            </div>
            <div className="flex-item">
              <h5>{t('footer.services')}</h5>
              <ul className="list-unstyled">
                <li>
                  <a href={`${WEB_LEGACY_LINK}/${prefix}/long-term-rental/`}>
                    {t('footer.long')}
                  </a>
                </li>
                <li>
                  <a href={`${WEB_LEGACY_LINK}/${prefix}/car-with-driver/`}>
                    {t('footer.with_driver')}
                  </a>
                </li>
                <li>
                  <Link to={`/${prefix}/how-to-book/`}>
                    {t('footer.how_to')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${prefix}/list-your-car//`}>
                    {t('footer.partner')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${prefix}/terms/`}>{t('footer.terms')}</Link>
                </li>
                <li>
                  <a
                    rel="nofollow noreferrer"
                    href={`${WEB_LEGACY_MANAGE_LINK}/th/employees/sign_in/`}
                  >
                    {t('footer.p_login')}
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex-item">
              <h5>{t('footer.std')}</h5>
              <ul className="list-unstyled">
                <li>
                  <Link to={`/${prefix}/car-standard/`}>
                    {t('footer.vehicle_std')}
                  </Link>
                </li>
                <li>
                  <Link to={`/${prefix}/privacy/`}>{t('footer.privacy')}</Link>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    )
  }
}
export default Footer
