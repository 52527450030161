import React from 'react'
import './style.scss'

export const LoadingWheel = props => {
  return (
    <div className="loading">
      <span className="loading-icon" style={{ height: props.height, width: props.width }} />
    </div>
  )
}
