import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import moment from 'moment-timezone'
import ReactGA from 'react-ga'
import TagManager from 'react-gtm-module'
import Header from '../header'
import Footer from '../footer'
import { Provider, withTranslation } from '../locales'
import { GA_ID, OPTIMIZE_ID, GTM_ID } from '../../../config'

import './custom.scss'
import 'moment/locale/th.js'
import 'rc-dialog/assets/index.css'
import '../../../static/assets/icon/flaticon.css'

moment.tz.setDefault('Asia/Bangkok')
moment.locale('th')

const Layout = props => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => <Index {...props} data={data} />}
  />
)

class Index extends Component {
  componentDidMount() {
    // NOTE: Initial GTM Tracking
    const tagManagerArgs = {
      gtmId: GTM_ID,
    }
    TagManager.initialize(tagManagerArgs)

    // NOTE: Initial GA
    ReactGA.initialize(GA_ID, {
      debug: false,
      titleCase: false,
      gaOptions: {
        siteSpeedSampleRate: 10,
        cookieDomain: 'drivehub.co',
      },
    })
    ReactGA.event({
      category: 'initial',
      action: 'initial',
      label: 'initial',
    })
    ReactGA.plugin.require('ec')
    ReactGA.ga('require', OPTIMIZE_ID, {
      name: 'Optimize-ID',
      cookieDomain: 'drivehub.co',
    })
  }

  render() {
    const {
      isMobile,
      children,
      prefix,
      theme,
      t,
      noCityList,
      isHomePage,
    } = this.props
    return (
      <Provider value={prefix}>
        <div className="header">
          <Header isMobile={isMobile} theme={theme} prefix={prefix} />
        </div>
        {children}
        <div className="footer">
          <Footer
            prefix={prefix}
            t={t}
            noCityList={noCityList}
            isHomePage={isHomePage}
          />
        </div>
      </Provider>
    )
  }
}

export default withTranslation('header-footer')(Layout)
