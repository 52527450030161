import React, { Component } from 'react'
import moment from 'moment-timezone'
import queryString from 'querystring'
import { Theme, NewMainMenu } from 'drivehub-rc'
import { WEB_SEARCH_LINK, WEB_LEGACY_LINK } from '../../../config'
import './style.scss'

moment.tz('Asia/bangkok')

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transparent: false,
      isLoadingPage: true,
    }
  }

  componentDidMount() {
    const pathname =
      typeof window !== 'undefined' ? window.location.pathname : ''
    if (
      pathname === '/' ||
      pathname.includes('long-term-rental') ||
      pathname.includes('car-with-driver') ||
      pathname === '/' ||
      pathname === '/th' ||
      pathname === '/en' ||
      pathname.includes('car-rental')
    ) {
      this.setState({
        transparent: true,
      })
    }
    this.setState({ isLoadingPage: false })
  }

  bookingBegin = () => {
    return moment()
      .add({
        days: 3,
      })
      .set({
        hours: 10,
        minutes: 0,
      })
      .format('YYYY-MM-DD HH:mm')
  }

  bookingEnd = () => {
    return moment()
      .add({
        days: 4,
      })
      .set({
        hours: 10,
        minutes: 0,
      })
      .format('YYYY-MM-DD HH:mm')
  }

  getSearchURL = () => {
    const parsedQuery = queryString.stringify({
      booking_begin: this.bookingBegin(),
      booking_end: this.bookingEnd(),
      location_id: 1,
      open_search: true,
    })

    return `${WEB_SEARCH_LINK}/?${parsedQuery}`
  }

  render() {
    const { isMobile, prefix } = this.props
    const { transparent, isLoadingPage } = this.state
    return (
      <Theme>
        {isLoadingPage === false && (
          <NewMainMenu
            size={isMobile ? 'mobile' : 'desktop'}
            prefix={prefix}
            renderMenu={(menu, onClick, arrow) => {
              if (menu.children && menu.children.length > 0) {
                return (
                  <div onClick={onClick} className="menu">
                    <span>{menu.title}</span> {arrow}
                  </div>
                )
              }
              if (menu.path.includes('short-term-rental')) {
                return (
                  <a
                    href={this.getSearchURL()}
                    className="menu"
                    key={menu.title}
                  >
                    {menu.title}
                  </a>
                )
              }
              return (
                <a
                  href={`${WEB_LEGACY_LINK}/${menu.path}`}
                  className="menu"
                  key={menu.title}
                >
                  {menu.title} {menu.postfix}
                </a>
              )
            }}
            transparent={transparent}
            renderLogoLink={children => (
              <a href={WEB_LEGACY_LINK}>{children}</a>
            )}
          />
        )}
      </Theme>
    )
  }
}

export default Header
