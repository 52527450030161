import longTermRentalFormTH from './th/long-term-rental-form.json'
import headerFooterTH from './th/header-footer.json'
import commonTH from './th/common.json'
import homepageTH from './th/homepage.json'

import longTermRentalFormEN from './en/long-term-rental-form.json'
import headerFooterEN from './en/header-footer.json'
import commonEN from './en/common.json'
import homepageEN from './en/homepage.json'

const locales = {
  th: {
    'long-term-rental-form': longTermRentalFormTH,
    common: commonTH,
    'header-footer': headerFooterTH,
    homepage: homepageTH,
  },
  en: {
    'long-term-rental-form': longTermRentalFormEN,
    common: commonEN,
    'header-footer': headerFooterEN,
    homepage: homepageEN,
  },
}
export default locales
