let SEARCH_WEB = ''
let LEGACY_WEB = ''
let LEGACY_MANAGE_WEB = ''
let SEARCH_API_URL = ''
let LEGACY_API_URL = ''
let LONG_TERM_RENTAL_API = ''
let _GTM_ID = ''
let _GA_ID = ''
let _OPTIMIZE_ID = ''
let _VWO_ID = ''
let CAR_WITH_DRIVER = ''
let _BIGBRAND_API = ''
let _MARKETING_API = ''

if (process.env.GATSBY_STAGE === 'production') {
  SEARCH_API_URL =
    process.env.GATSBY_SEARCH_API_URL || 'https://search-api.drivehub.co/api'
  SEARCH_WEB = process.env.GATSBY_SEARCH_WEB || 'https://search.drivehub.co'
  LEGACY_WEB = process.env.GATSBY_LEGACY_WEB || 'https://www.drivehub.co'
  LEGACY_API_URL =
    process.GATSBY_LEGACY_API_URL || 'https://backend.drivehub.co/api'
  _GTM_ID = process.env.GATSBY_GTM_ID || 'GTM-N39C5GZ'
  _GA_ID = process.env.GATSBY_GA_ID || 'UA-102591477-1'
  _OPTIMIZE_ID = process.env.GATSBY_OPTIMIZE_ID || 'GTM-KNDPHXB'
  _VWO_ID = process.env.GATSBY_VWO_ID || '315025'
  LONG_TERM_RENTAL_API = process.env.GATSBY_LONG_TERM_RENTAL_API
  LEGACY_MANAGE_WEB =
    process.env.GATSBY_LEGACY_MANAGE_WEB || 'https://manage.drivehub.co'
  CAR_WITH_DRIVER = process.env.GATSBY_CAR_WITH_DRIVER
  _BIGBRAND_API =
    process.env.GATSBY_BIGBRAND_API_URL || 'https://bigbrand.drivehub.co/api'
  _MARKETING_API =
    process.env.GATSBY_MARKETING_API_URL ||
    'https://marketing-api.drivehub.co/api'
} else {
  SEARCH_API_URL = 'https://search-api-dev.drivehub.co/api'
  SEARCH_WEB = 'https://app-dev.drivehub.co'
  LEGACY_WEB = 'https://drivehub-dev.drivehub.co'
  LEGACY_API_URL = 'https://backend-dev.drivehub.co/api'
  _GTM_ID = 'GTM-N39C5GZ'
  _GA_ID = 'UA-102591477-1'
  _OPTIMIZE_ID = 'GTM-KNDPHXB'
  _VWO_ID = '315025' // Use the same account as 'production'
  LONG_TERM_RENTAL_API = 'https://drivehub-dev.firebaseapp.com/api'
  LEGACY_MANAGE_WEB = 'https://manage-dev.drivehub.co'
  CAR_WITH_DRIVER = 'https://car-with-driver-dev.drivehub.co'
  _BIGBRAND_API = 'https://bigbrand-dev.drivehub.co/api'
  _MARKETING_API = 'https://marketing-api-dev.drivehub.co/api'
}

export const GTM_ID = _GTM_ID
export const GA_ID = _GA_ID
export const OPTIMIZE_ID = _OPTIMIZE_ID
export const VWO_ID = _VWO_ID
export const WEB_SEARCH_LINK = SEARCH_WEB
export const WEB_LEGACY_LINK = LEGACY_WEB
export const WEB_LEGACY_MANAGE_LINK = LEGACY_MANAGE_WEB
export const API_LEGACY_LINK = `${LEGACY_API_URL}/v2`
export const API_GET_LOCATIONS = `${SEARCH_API_URL}/locations`
export const API_GET_CARS = `${SEARCH_API_URL}/cars`
export const API_LONG_TERM_RENTAL = LONG_TERM_RENTAL_API
export const API_CAR_WITH_DRIVER = CAR_WITH_DRIVER
export const BIGBRAND_API = _BIGBRAND_API
export const MARKETING_API = _MARKETING_API
// [ BADGE-SECTION ]
export const INSTANT_BOOKING_BADGE = 'FAST60'
