import moment from 'moment'

export function readCookie(name) {
  var nameEQ = name + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export function setCookie(cname, cvalue, exdays, cpath) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie =
    cname +
    '=' +
    cvalue +
    ';' +
    expires +
    `;path=${cpath}` +
    ';domain=.drivehub.co'
  // ';domain=localhost'
}

export function setupDateTime(isPickup, type) {
  const currentDateTime = roundUpTime(moment())
  const pickupDateTime = checkTimeCondition(currentDateTime)

  if (isPickup) {
    if (type === 'inter') return pickupDateTime.add(5, 'days')
    return pickupDateTime
  } else {
    if (type === 'inter') return pickupDateTime.add(7, 'days')
    return pickupDateTime.add(2, 'days')
  }
}

function roundUpTime(dateTime) {
  const minute = dateTime.minutes()
  if (minute <= 30) {
    const diffTime = 30 - minute
    return dateTime.add(diffTime, 'minutes')
  } else {
    const diffTime = 60 - minute
    return dateTime.add(diffTime, 'minutes')
  }
}

function checkTimeCondition(dateTime) {
  const hour = dateTime.hours()
  if ([18, 19].includes(hour)) {
    return dateTime.set({ hour: 22, minute: 0 })
  } else if (hour >= 20) {
    return dateTime.add({ days: 1 }).set({ hour: 10, minute: 0 })
  } else if (hour <= 6) {
    return dateTime.set({ hour: 10, minute: 0 })
  } else {
    return dateTime.add({ hours: 4 })
  }
}
