import React from 'react'
import resources from './resources'
import { Consumer } from './index'
/**
 * @param  {string} namespace - Context Namespace
 */
const localHoc = (namespace = '', ...extra) => Component => {
  class WrappedComponent extends React.Component {
    state = {}
    /**
     * @param  {string} text - Translate Phase
     * @param  {string} lang - Selected Language
     */
    translate = (text, lang) => {
      const paths = text.split('.')
      const list = [namespace, ...extra]
      const context = list.reduce((p, c) => {
        return {
          ...p,
          ...resources[lang][c],
        }
      }, {})
      try {
        const response = paths.reduce((p, c) => p[c], context)
        return response ? response : text
      } catch (error) {
        return text
      }
    }
    render() {
      return (
        <Consumer>
          {lang => (
            <Component
              {...this.props}
              /**
               * @param  {string} text - Translate Phase
               */
              t={phase => this.translate(phase, lang)}
              lang={lang}
            />
          )}
        </Consumer>
      )
    }
  }
  return WrappedComponent
}

export default localHoc
